import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Preloader from "../Components/Preloader/Preloader";
import "./Auth.css"

const EmailChange = (props) => {
    const [code, setCode] = useState("");
    const [info, setInfo] = useState(null)
    const [should_load, setShouldLoad] = useState(true)
    const [change_token_sent, setChangeTokenSent] = useState(false)
    const [change_token_verified, setChangeTokenVerified] = useState(false)
    const [phone_verification_token_sent, setPhoneVerificationTokenSent] = useState(false)
    const [email_verification_token_sent, setEmailVerificationTokenSent] = useState(false)
    const [new_email, setNewEmail] = useState("")
    const [new_email_verified, setNewEmailVerified] = useState(false)
    const [change_method, setChangeMethod] = useState({'use_email': false, 'use_phone': false})
    const [from_email_token_yeet, setFromEmailTokenYeet] = useState(null)
    const navigate = useNavigate();
    const jwt = JSON.parse(localStorage.getItem("user")).token;
    let from_email_token = useParams();
    if (from_email_token.emailToken && from_email_token_yeet === null) {
        setFromEmailTokenYeet(from_email_token.emailToken)
        setInfo({'dummydata': 'yeet' })
        if (from_email_token.verify) {
            setChangeTokenSent(true)
            setCode(from_email_token.verify)
        } else {
            setEmailVerificationTokenSent(true)
            setCode(from_email_token.emailToken)
        }
    }

    useEffect(() => {
        const checkUser = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/info", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setInfo(r.data)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert("The server is having issues processing your request")
                            navigate("/email/change")
                        }
                    })
            }, 2000);
        }
        if (should_load === true && info === null) {
            checkUser()
        }
    }, [navigate, info, code, jwt, setInfo, should_load]);

    useEffect(() => {
        const request_change = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/email/change", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).token
                    },
                    body: JSON.stringify(change_method)
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            if (change_method.use_phone) {
                                setShouldLoad(false)
                                setChangeMethod({'use_email': false, 'use_phone': false})
                                setPhoneVerificationTokenSent(true)
                            } else {
                                setShouldLoad(false)
                                setChangeMethod({'use_email': false, 'use_phone': false})
                                setEmailVerificationTokenSent(true)
                            }
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert(r.message)
                            setShouldLoad(false)
                        }
                    })
            }, 2000)
        }
        if(should_load && (change_method.use_phone || change_method.use_email)) {
            request_change()
        }
    }, [change_method, navigate, change_method, setShouldLoad, setPhoneVerificationTokenSent, setEmailVerificationTokenSent, should_load, setShouldLoad]);

    const handleSubmitRequestChange = (e) => {
        e.preventDefault();
        setShouldLoad(true)
    };

    useEffect(() => {
        const check_change = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/email/change/" + code +"/verify", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).token
                    },
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            if (from_email_token.emailToken) {
                                setInfo(r.data)
                            }
                            setChangeTokenVerified(true)
                            setPhoneVerificationTokenSent(false)
                            setEmailVerificationTokenSent(false)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert(r.message)
                            setShouldLoad(false)
                            setPhoneVerificationTokenSent(false)
                            setEmailVerificationTokenSent(false)
                        }
                    })
            }, 2000)
        }
        if (should_load && (phone_verification_token_sent || email_verification_token_sent)) {
            check_change()
        }
    }, [should_load, setChangeTokenSent, setChangeTokenVerified, setShouldLoad, code, navigate, change_token_verified, phone_verification_token_sent, email_verification_token_sent, setPhoneVerificationTokenSent, setEmailVerificationTokenSent]);

    const handleCheckChange = (e) => {
        e.preventDefault()
        setShouldLoad(true)
    }

    useEffect(() => {
        const change = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/email/change/" + code, {
                    method: "PATCH",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).token
                    },
                    body: JSON.stringify({"new_email": new_email})
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setChangeTokenSent(true)
                            setChangeTokenVerified(false)
                            setNewEmail("")
                            setCode("")
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            setShouldLoad(false)
                            window.alert(r.message)
                            console.log(r.data)
                            setNewEmail("")
                            setCode("")
                        }
                    })
            }, 2000)
        }
        if (should_load && change_token_verified && new_email !== "") {
            change()
        }
    }, [should_load, new_email, setChangeTokenSent, setNewEmail, navigate, change_token_verified, code, setShouldLoad, setCode]);

    const handleSubmitChange = (e) => {
        e.preventDefault();
        setShouldLoad(true)
    }

    useEffect(() => {
        const verify_change = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/email/change/verify/" + code, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).token
                    }
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setNewEmailVerified(true)
                            setChangeTokenSent(false)
                            setCode("")
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            setShouldLoad(false)
                            window.alert(r.message)
                            console.log(r.data)
                            setChangeTokenSent(false)
                            setCode("")
                        }
                    })
            }, 2000)
        }
        if (should_load && change_token_sent) {
            verify_change()
        }
    }, [should_load, setNewEmailVerified, navigate, change_token_sent, setShouldLoad, setCode, code, setChangeTokenVerified, setChangeTokenSent]);

    const handleSubmitChangeVerification = (e) => {
        e.preventDefault()
        setShouldLoad(true)
    }

    const onRadioButtonChange = (e) => {
        const { name } = e.target
        if (name === 'email') {
            setChangeMethod({ use_email: true, use_phone: false })
        }
        if (name === 'phone') {
            setChangeMethod({ use_email: false, use_phone: true })
        }
    }

    const onBack = (e) => {
        navigate("/user/edit")
    }

    let current_state = <div></div>
    if (new_email_verified) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>Your new email has been verified</h2>
                    <button className='codeBtn' onClick={onBack}>BACK</button>
                </div>
            </React.Fragment>
        );
    } else if (change_token_sent) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>A verification link has been sent to your new email</h2>
                </div>
            </React.Fragment>
        );
    } else if (change_token_verified) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>New Email Address</h2>
                    <form className='verify__form' onSubmit={handleSubmitChange}>
                        <label htmlFor='code' style={{ marginBottom: "10px" }}>
                            Please enter your new email address
                        </label>
                        <input
                            type='text'
                            name='code'
                            id='code'
                            className='code'
                            value={new_email}
                            onChange={(e) => setNewEmail(e.target.value)}
                            required
                        />
                        <button className='codeBtn'>NEXT</button>
                    </form>
                </div>
            </React.Fragment>
        );
    } else if (phone_verification_token_sent) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>Email Change Authorization</h2>
                    <form className='verify__form' onSubmit={handleCheckChange}>
                        <label htmlFor='code' style={{ marginBottom: "10px" }}>
                            A code has been sent to your phone
                        </label>
                        <input
                            type='text'
                            name='code'
                            id='code'
                            className='code'
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                        />
                        <button className='codeBtn'>NEXT</button>
                    </form>
                </div>
            </React.Fragment>
        );
    } else if (email_verification_token_sent) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>An email has been sent to your address with a link</h2>
                </div>
            </React.Fragment>
        );
    } else {
        if (info != null) {
            current_state = (
                <React.Fragment>
                    <div className='verify'>
                        <h2 style={{ marginBottom: "30px" }}>Email Change Request</h2>
                        <form className='verify__form' onSubmit={handleSubmitRequestChange}>
                            <label htmlFor='code' style={{ marginBottom: "20px" }}>
                                Please select the method you would like to use
                            </label>
                            <label htmlFor="phone">
                                <input
                                    className="custom-radio"
                                    type="radio"
                                    name="phone"
                                    id="phone"
                                    value="phone"
                                    onChange={onRadioButtonChange}
                                    checked={change_method.use_phone}
                                />
                                Phone: {info.phone}
                            </label>
                            <label htmlFor="email">
                                <input
                                    className="custom-radio"
                                    type="radio"
                                    name="email"
                                    id="email"
                                    value="email"
                                    onChange={onRadioButtonChange}
                                    checked={change_method.use_email}
                                />
                                Email: {info.email}
                            </label>
                            <button className='codeBtn' style={{ marginTop: "20px"}}>REQUEST CHANGE</button>
                        </form>
                    </div>
                </React.Fragment>
            );
        }
    }

    return(
        <>
            {should_load ? (
                <Preloader />
            ) : (
                current_state
            )}
        </>
    );
};

export default EmailChange;