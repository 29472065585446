import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, {useState} from "react";
import Navbar from "./Components/Navbar/Navbar";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import Dashboard from "./Dashboard/Dashboard";
import PhoneVerify from "./Auth/PhoneVerify";
import EmailVerify from "./Auth/EmailVerify"
import PhoneTFA from "./Auth/PhoneTFA"
import PhoneChange from "./Auth/PhoneChange";
import EmailChange from "./Auth/EmailChange";
import Logout from "./Auth/Logout";
import "./App.css"
import UserEdit from "./User/UserEdit";
import PasswordReset from "./Auth/PasswordReset";

function App() {
    const [loggedIn, setLoggedIn] = useState(false)
    const [username_or_email, setUsernameEmail] = useState(null)
    const [password, setPassword] = useState("")
    const [phone_number, setPhone] = useState("")
    const [email, setEmail] = useState("")

    if (username_or_email === null) {
        let data = JSON.parse(localStorage.getItem("user"))
        if (data) {
            setUsernameEmail(data.username_or_email)
        } else {
            setUsernameEmail("")
        }
    }

        return (
            <React.Fragment>
                <div className="bg-primary w-full z-50">
                    <header className="bg-white fixed top-0 w-full">
                        <Navbar username={username_or_email} />
                    </header>
                </div>
                <main>
                    <div className="main__body">
                        <BrowserRouter>
                            <Routes>
                                <Route path='/' element={<Dashboard email={username_or_email} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} />
                                <Route path='/login' element={<Login setLoggedIn={setLoggedIn} setUsernameEmail={setUsernameEmail} setPassword={setPassword} setPhone={setPhone} />} />
                                <Route path='/logout' element={<Logout setUsernameEmail={setUsernameEmail} setLoggedIn={setLoggedIn} />} />
                                <Route path='/register' element={<Signup setPhone={setPhone} />} />
                                <Route path='/dashboard' element={<Dashboard email={username_or_email} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} />
                                <Route path='/user/edit' element={<UserEdit setPhone={setPhone} setEmail={setEmail} />} />
                                <Route path='/phone/verify' element={<PhoneVerify phone={phone_number} />} />
                                <Route path='/email/verify/:emailToken?' element={<EmailVerify email={email} />} />
                                <Route path='/phone/change/:phoneToken?' element={<PhoneChange />} />
                                <Route path='/email/change/:emailToken?/:verify?' element={<EmailChange />} />
                                <Route path='/password/reset/:emailToken?' element={<PasswordReset phone={phone_number} email={email} />} />
                                <Route path='/2fa' element={<PhoneTFA setLoggedIn={setLoggedIn} setUsernameEmail={setUsernameEmail} phone={phone_number} password={password} setPassword={setPassword} username_or_email={username_or_email} />} />
                            </Routes>
                        </BrowserRouter>
                    </div>
                </main>
            </React.Fragment>
        );
    }

export default App;