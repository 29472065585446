import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Preloader from "../Components/Preloader/Preloader";
import "./Auth.css"

const PasswordReset = (props) => {
    const [info, setInfo] = useState(null)
    const [code, setCode] = useState("")
    const [should_load, setShouldLoad] = useState(true)
    const [password_token_verified, setPasswordTokenVerified] = useState(false)
    const [new_password, setNewPassword] = useState("")
    const [new_password_confirm, setNewPasswordConfirm] = useState("")
    const [phone_token_sent, setPhoneTokenSent] = useState(false)
    const [email_token_sent, setEmailTokenSent] = useState(false)
    const [password_token, setPasswordToken] = useState(null)
    const [password_changed, setPasswordChanged] = useState(false)
    const [user_supplied_email, setUserSuppliedEmail] = useState("")
    const [user_supplied_phone, setUserSuppliedPhone] = useState("")
    const [type_info, setTypeInfo] = useState(false)
    const [change_method, setChangeMethod] = useState({'use_email': false, 'use_phone': false})
    const [from_email_token_yeet, setFromEmailTokenYeet] = useState(null)
    const navigate = useNavigate();
    const cookie_info =JSON.parse(localStorage.getItem("user"))
    const jwt = cookie_info !== null ? cookie_info.token : null;
    let from_email_token = useParams();
    if (from_email_token.emailToken && from_email_token_yeet === null) {
        setFromEmailTokenYeet(from_email_token.emailToken)
        setPasswordToken(from_email_token.emailToken)
        setChangeMethod({'use_email': true, 'use_phone': false})
        setEmailTokenSent(true)
    }

    useEffect(() => {
        const checkUser = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/info", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setInfo(r.data)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert("The server is having issues processing your request")
                            navigate("/user/edit")
                        }
                    })
            }, 2000);
        }
        if (should_load === true && info === null && jwt !== null && !email_token_sent && !phone_token_sent) {
            checkUser()
        } else if (should_load === true && info === null && jwt === null && !type_info && !email_token_sent && !phone_token_sent) {
            setShouldLoad(false)
        }
    }, [navigate, info, jwt, setInfo, should_load, email_token_sent, phone_token_sent]);

    useEffect(() => {
        const request_change_phone = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/password/reset/phone", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({"phone_number": info !== null ? info.phone : user_supplied_phone})
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setPhoneTokenSent(true)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert(r.message)
                            setShouldLoad(false)
                        }
                    })
            }, 2000)
        }
        if(should_load && change_method.use_phone && (info !== null || user_supplied_phone !== "") && !phone_token_sent) {
            request_change_phone()
        }
    }, [change_method, navigate, change_method, setShouldLoad, setPhoneTokenSent, should_load, setShouldLoad, phone_token_sent, info, user_supplied_phone]);

    useEffect(() => {
        const request_change_phone = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/password/reset/email", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({"email": info !== null ? info.email : user_supplied_email})
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setEmailTokenSent(true)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert(r.message)
                            setShouldLoad(false)
                        }
                    })
            }, 2000)
        }
        if(should_load && change_method.use_email && (info !== null || user_supplied_email !== "" ) && !email_token_sent) {
            request_change_phone()
        }
    }, [change_method, navigate, change_method, setShouldLoad, setEmailTokenSent, should_load, setShouldLoad, email_token_sent, info, user_supplied_email]);

    useEffect(() => {
        const request_change_verify = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/password/reset/phone/" + code + "/verify", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({"phone_number": info !== null ? info.phone : user_supplied_phone})
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setPasswordTokenVerified(true)
                            setPasswordToken(r.data.token)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert(r.message)
                            console.log(r.data)
                            setShouldLoad(false)
                        }
                    })
            }, 2000)
        }
        if(should_load && change_method.use_phone && phone_token_sent && code !== "" && !password_token_verified) {
            request_change_verify()
        }
    }, [change_method, navigate, change_method, setShouldLoad, should_load, setShouldLoad, phone_token_sent, setPasswordTokenVerified, code, password_token_verified, setPasswordToken]);

    useEffect(() => {
        const request_change_verify = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/password/reset/email/" + password_token + "/verify", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setPasswordTokenVerified(true)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert(r.message)
                            setShouldLoad(false)
                        }
                    })
            }, 2000)
        }
        if(should_load && change_method.use_email && email_token_sent && password_token !== null && !password_token_verified) {
            request_change_verify()
        }
    }, [change_method, navigate, change_method, setShouldLoad, should_load, setShouldLoad, email_token_sent, setPasswordTokenVerified, code, password_token_verified]);

    useEffect(() => {
        const change = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/password/reset/" + password_token, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({"password": new_password, "password_confirm": new_password_confirm})
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setPasswordChanged(true)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert(r.message)
                            setShouldLoad(false)
                        }
                    })
            }, 2000)
        }
        if(should_load && password_token !== null && password_token_verified) {
            change()
        }
    }, [change_method, navigate, setShouldLoad, should_load, setShouldLoad, password_token, setPasswordChanged, password_token_verified]);

    const handleSubmit = (e) => {
        e.preventDefault()
        setShouldLoad(true)
    }

    const handleSubmitRequest = (e) => {
        e.preventDefault()
        if (info !== null) {
            setShouldLoad(true)
        } else {
            setTypeInfo(true)
        }
    }

    const onRadioButtonChange = (e) => {
        const { name } = e.target
        if (name === 'email') {
            setChangeMethod({ use_email: true, use_phone: false })
        }
        if (name === 'phone') {
            setChangeMethod({ use_email: false, use_phone: true })
        }
    }

    const onBack = (e) => {
        if (jwt !== null) {
            navigate("/user/edit")
        } else {
            navigate("/login")
        }
    }

    let current_state = <div></div>
    if (password_changed) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>Your password has been changed</h2>
                    <button className='codeBtn' onClick={onBack}>BACK</button>
                </div>
            </React.Fragment>
        );
    } else if (password_token_verified) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>New Password</h2>
                    <form className='verify__form' onSubmit={handleSubmit}>
                        <label htmlFor='code' style={{ marginBottom: "10px" }}>
                            New Password
                        </label>
                        <input
                            type='password'
                            name='password'
                            id='password'
                            minLength={8}
                            required
                            value={new_password}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <label htmlFor='code' style={{ marginBottom: "10px" }}>
                            Confirm
                        </label>
                        <input
                            type='password'
                            name='password'
                            id='password_confirm'
                            minLength={8}
                            required
                            value={new_password_confirm}
                            onChange={(e) => setNewPasswordConfirm(e.target.value)}
                        />
                        <button className='codeBtn'>NEXT</button>
                    </form>
                </div>
            </React.Fragment>
        );
    } else if (phone_token_sent) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>Password Change Code</h2>
                    <form className='verify__form' onSubmit={handleSubmit}>
                        <label htmlFor='code' style={{ marginBottom: "10px" }}>
                            A code has been sent to your phone
                        </label>
                        <input
                            type='text'
                            name='code'
                            id='code'
                            className='code'
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                        />
                        <button className='codeBtn'>NEXT</button>
                    </form>
                </div>
            </React.Fragment>
        );
    } else if (email_token_sent) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>An email has been sent to your address with a link</h2>
                </div>
            </React.Fragment>
        );
    } else if (type_info) {
        if (change_method.use_phone) {
            current_state = (
                <React.Fragment>
                    <div className='verify'>
                        <h2 style={{ marginBottom: "30px" }}>Phone Number</h2>
                        <form className='verify__form' onSubmit={handleSubmit}>
                            <label htmlFor='code' style={{ marginBottom: "10px" }}>
                                Please enter the phone number associated with your account
                            </label>
                            <input
                                type='text'
                                name='code'
                                id='code'
                                className='code'
                                value={user_supplied_phone}
                                onChange={(e) => setUserSuppliedPhone(e.target.value)}
                                required
                            />
                            <button className='codeBtn'>NEXT</button>
                        </form>
                    </div>
                </React.Fragment>
            );
        } else if (change_method.use_email) {
            current_state = (
                <React.Fragment>
                    <div className='verify'>
                        <h2 style={{ marginBottom: "30px" }}>Email Address</h2>
                        <form className='verify__form' onSubmit={handleSubmit}>
                            <label htmlFor='code' style={{ marginBottom: "10px" }}>
                                Please enter the email address associated with your account
                            </label>
                            <input
                                type='text'
                                name='code'
                                id='code'
                                className='code'
                                value={user_supplied_email}
                                onChange={(e) => setUserSuppliedEmail(e.target.value)}
                                required
                            />
                            <button className='codeBtn'>NEXT</button>
                        </form>
                    </div>
                </React.Fragment>
            );
        }
    } else {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>Password Reset Request</h2>
                    <form className='verify__form' onSubmit={handleSubmitRequest}>
                        <label htmlFor='code' style={{ marginBottom: "20px" }}>
                            Please select the method you would like to use
                        </label>
                        <label htmlFor="phone">
                            <input
                                className="custom-radio"
                                type="radio"
                                name="phone"
                                id="phone"
                                value="phone"
                                onChange={onRadioButtonChange}
                                checked={change_method.use_phone}
                            />
                            {info !== null ? (
                                <>Phone: {info.phone}</>
                            ) : (
                                <>Phone</>
                            )}
                        </label>
                        <label htmlFor="email">
                            <input
                                className="custom-radio"
                                type="radio"
                                name="email"
                                id="email"
                                value="email"
                                onChange={onRadioButtonChange}
                                checked={change_method.use_email}
                            />
                            {info !== null ? (
                                <>Email: {info.email}</>
                            ) : (
                                <>Email</>
                            )}
                        </label>
                        <button className='codeBtn' style={{ marginTop: "20px"}}>REQUEST CHANGE</button>
                    </form>
                </div>
            </React.Fragment>
        );
    }

    return(
        <>
            {should_load ? (
                <Preloader />
            ) : (
                current_state
            )}
        </>
    );
};

export default PasswordReset;