import "./Preloader.css"
import ReactLoading from "react-loading";
import React from "react";

const Preloader = () => {
    return (
        <div className="loading">
            <ReactLoading
                type={"bars"}
                color={"#61dafb"}
                height={100}
                width={100}
            />
        </div>
    )
};

export default Preloader;