import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.css"

const PhoneTFA = (props) => {
    const [code, setCode] = useState("");
    const navigate = useNavigate();

    const logIn = () => {
        fetch("https://api.puzl.tech/auth/login/2fa/" + code, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'username_or_email': props.username_or_email, 'password': props.password, 'phone_number': props.phone})
        })
            .then(r => r.json())
            .then(r => {
                if ('Login successful' === r.message) {
                    localStorage.setItem("user", JSON.stringify({'username_or_email': r.data.username, token: r.data.token}))
                    props.setLoggedIn(true)
                    props.setUsernameEmail(r.data.username)
                    props.setPassword("")
                    navigate("/dashboard")
                } else {
                    window.alert(r.message)
                }
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        logIn()
        setCode("");
    };

    return (
        <div className='verify'>
            <h2 style={{ marginBottom: "30px" }}>Enter the code sent to your phone</h2>
            <form className='verify__form' onSubmit={handleSubmit}>
                <label htmlFor='code' style={{ marginBottom: "10px" }}>
                    A code has been sent your phone
                </label>
                <input
                    type='text'
                    name='code'
                    id='code'
                    className='code'
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                />
                <button className='codeBtn'>AUTHENTICATE</button>
            </form>
        </div>
    );
};

export default PhoneTFA;