import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "./Auth.css"
import Preloader from "../Components/Preloader/Preloader";

const EmailVerify = (props) => {
    const [code, setCode] = useState("");
    const [should_load, setShouldLoad] = useState(true)
    const [code_sent, setCodeSent] = useState(false)
    const [email_token_yeet, setEmailTokenYeet] = useState(null)
    const [email_verified, setEmailVerified] = useState(false)
    const navigate = useNavigate();
    const jwt = JSON.parse(localStorage.getItem("user")).token;
    let email_token = useParams();
    if (email_token.emailToken && email_token_yeet === null) {
        setEmailTokenYeet(email_token.emailToken)
        setCode(email_token.emailToken)
    }

    useEffect(() => {
        const checkUser = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/email/verify", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                    body: JSON.stringify({"email": props.email})
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setCodeSent(true)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert("The server is having issues processing your request")
                            navigate("/user/edit")
                        }
                    })
            }, 2000);
        }
        if (should_load === true && code_sent === false && email_token_yeet === null) {
            checkUser()
        }
    }, [navigate, code_sent, jwt, setCodeSent, should_load, email_token_yeet, setShouldLoad]);

    useEffect(() => {
        const verifyEmail = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/email/verify/" + email_token_yeet, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setEmailVerified(true)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert(r.message)
                            navigate("/user/edit")
                        }
                    })
            }, 2000);
        }
        if (should_load === true && email_token_yeet !== null) {
            verifyEmail()
        }
    }, [navigate, should_load, email_token_yeet, setEmailVerified, setShouldLoad]);

    const onBack = (e) => {
        navigate("/user/edit")
    }

    let current_state = <div></div>

    if (email_verified) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>Your email has been verified</h2>
                    <button className='codeBtn' onClick={onBack}>BACK</button>
                </div>
            </React.Fragment>
        );
    } else if (code_sent) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>An email has been sent to your address with a verification link</h2>
                </div>
            </React.Fragment>
        );
    }

    return (
        <>
            {should_load ? (
                <Preloader />
            ) : (
                current_state
            )}
        </>
    );
};

export default EmailVerify;