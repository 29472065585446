import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.css"

const Signup = (props) => {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const signUp = () => {
        fetch("https://api.puzl.tech/auth/signup", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: "follow",
            body: JSON.stringify({username, email, phone, password})
        })
            .then(r => r.json())
            .then(r => {
                if ('Signup successful' === r.message) {
                    props.setPhone(phone)
                    navigate("/login")
                } else {
                    window.alert(r.message)
                }
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        signUp()
        setEmail("");
        setPhone("");
        setUsername("");
        setPassword("");
    };
    const gotoLoginPage = () => navigate("/login");

    return (
        <div className='signup__container'>
            <h2>Sign up </h2>
            <form className='signup__form' onSubmit={handleSubmit}>
                <label htmlFor='email'>Email Address</label>
                <input
                    type='email'
                    name='email'
                    id='email'
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor='username'>Username</label>
                <input
                    type='text'
                    id='username'
                    name='username'
                    value={username}
                    required
                    onChange={(e) => setUsername(e.target.value)}
                />
                <label htmlFor='tel'>Phone Number</label>
                <input
                    type='tel'
                    name='tel'
                    id='tel'
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                />
                <label htmlFor='tel'>Password</label>
                <input
                    type='password'
                    name='password'
                    id='password'
                    minLength={8}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className='signupBtn'>SIGN UP</button>
                <p>
                    Already have an account?{" "}
                    <span className='link' onClick={gotoLoginPage}>
                        Login
                    </span>
                </p>
            </form>
        </div>
    );
};

export default Signup;