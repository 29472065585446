import React, {useEffect, useState} from 'react';
import './UserEdit.css';
import DefaultImage from '../Images/default.png'
import {useNavigate} from "react-router-dom";
import Preloader from "../Components/Preloader/Preloader";

const UserEdit = (props) => {
    const [info, setInfo] = useState(null)
    const [username, setUsername] = useState(null)
    const [should_load, setShouldLoad] = useState(true)
    const [tfa_enabled, setTFAEnabled] = useState(false)
    const [submit_changes, setSubmitChanges] = useState(false)
    const navigate = useNavigate();
    const jwt = JSON.parse(localStorage.getItem("user")).token;

    useEffect(() => {
        const checkUser = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/info", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setInfo(r.data)
                            setTFAEnabled(r.data.tfa_enabled)
                            setUsername(r.data.username)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert("The server is having issues processing your request")
                            navigate("/user/edit")
                        }
                    })
            }, 2000);
        }
        if (should_load === true && info === null) {
            checkUser()
        }
    }, [navigate, info, jwt, setInfo, should_load, setTFAEnabled]);

    useEffect(() => {
        const changeTFA = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/tfa", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert("The server is having issues processing your request")
                            navigate("/user/edit")
                        }
                    })
            }, 2000);
        }
        if (should_load && submit_changes) {
            if (info.tfa_enabled !== tfa_enabled) {
                changeTFA()
            }
        }
    }, [navigate, jwt, should_load]);

    const onChangeEmail = (e) => {
        props.setEmail(info.email)
        navigate("/email/change")
    }

    const onChangePhone = (e) => {
        props.setPhone(info.phone)
        navigate("/phone/change")
    }

    const onVerifyEmail = (e) => {
        props.setEmail(info.email)
        navigate("/email/verify")
    }

    const onVerifyPhone = (e) => {
        props.setPhone(info.phone)
        navigate("/phone/verify")
    }

    const onChangePassword = (e) => {
        props.setPhone(info.phone)
        props.setEmail(info.email)
        navigate("/password/reset")
    }

    const onChangeTFA = (e) => {
        setTFAEnabled(!tfa_enabled)
    }

    const onSubmit = (e) => {
        setSubmitChanges(true)
        setShouldLoad(true)
    }

    const onCancel = (e) => {
        navigate("/dashboard")
    }

    let current_state = <div></div>

    if (info !== null) {
        current_state = (
            <div className='login__container'>
                <div className="profile-avatar" >
                    <img src={DefaultImage} alt="User avatar"/>
                </div>
                <h2 className='h2-separation'>Account info</h2>
                <form className='login__form'>
                    <label>Username</label>
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <label>Phone number</label>
                    <div className="button-container">
                        <input type="tel" value={info.phone} readOnly={true} style={{ width: "100%"}}/>
                        {info.phone_verified ? (<></>) : (<button type="submit" className="otherBtn button-separation" onClick={onVerifyPhone}>Verify</button>)}
                        <button type="submit" className="otherBtn button-separation" onClick={onChangePhone}>Change</button>
                    </div>
                    <label>Email address</label>
                    <div className="button-container">
                        <input type="email" value={info.email} readOnly={true} style={{ width: "100%"}}/>
                        {info.email_verified ? (<></>) : (<button type="submit" className="otherBtn button-separation" onClick={onVerifyEmail}>Verify</button>)}
                        <button type="submit" className="otherBtn button-separation" onClick={onChangeEmail}>Change</button>
                    </div>
                </form>
                <h2 className='h2-separation'>Security</h2>
                <form className='login__form'>
                    <div className="button-container" style={{ marginBottom: "10%", justifyContent: "center"}}>
                        <button type="submit" onClick={onChangePassword} className="codeBtn">Change Password</button>
                        <div>
                            <div className="button-container" style={{ marginLeft: "20%"}}>
                                <input type="checkbox" onChange={onChangeTFA} checked={tfa_enabled} disabled={!info.phone_verified} id="checkbox_1"/>
                                <label form="checkbox_1" style={{ marginTop: "8%", marginLeft: "10px"}}>Two-Factor Authentication</label>
                            </div>
                            <div className="button-container" style={{ marginLeft: "20%"}}>
                                <input type="checkbox" id="checkbox_2"/>
                                <label form="checkbox_1" style={{ marginTop: "8%", marginLeft: "10px"}}>Make this account public</label>
                            </div>
                        </div>
                    </div>
                    <div className="button-container" style={{ justifyContent: "center"}}>
                        <button type="submit" className="otherBtn button-separation" onClick={onSubmit}>Submit</button>
                        <button type="reset" className="otherRedBtn button-separation" onClick={onCancel}>Cancel</button>
                    </div>
                </form>
            </div>
        );
    }

    return (
        <>
            {should_load ? (
                <Preloader />
            ) : (
                current_state
            )}
        </>
    );
}
export default UserEdit;