import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../Components/Preloader/Preloader";

const Logout = (props) => {
    const [test, setTest] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("user");
        setTest(true)
        props.setUsernameEmail("")
        props.setLoggedIn(false)
        navigate("/");
    })

    return (
        <div className='dashboard'>
            <Preloader />
        </div>
    );
};

export default Logout;