import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import PUZLCard from "../Components/Card/PUZLCard";

const Dashboard = () => {
    const navigate = useNavigate();
    const [username_or_email, setUsernameOrEmail] = useState(localStorage.getItem("user"))

    useEffect(() => {
        const checkUser = () => {
            if (username_or_email === null ) {
                navigate("/login");
            }
        };
        checkUser();
    }, [navigate]);

    const createCards = () => {

    }

    let posts = []
    for (let i = 0; i < 20; i++) {
        posts.push({ username: 'phillipino97', created_at: '2023-11-30', text: 'This is a test of this stupid social media thing'})
    }

    return (
        <div className='dashboard'>
            {posts.map((item,index)=>{
                return <PUZLCard key={index} username={item.username} created_at={item.created_at} text={item.text} />
            })}
        </div>
    );
};

export default Dashboard;