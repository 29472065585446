import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../Components/Preloader/Preloader";
import "./Auth.css"

const Login = (props) => {
    const [username_or_email, setUsernameEmail] = useState("");
    const [password, setPassword] = useState("");
    const [is_checking_user, setIsCheckingUser] = useState(false)
    const navigate = useNavigate();
    const current_user = localStorage.getItem("user")

    useEffect(() => {
        const checkUser = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/jwt/verify", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(current_user).token
                    }
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            navigate("/")
                        } else if ("Unauthorized to perform this action." === r.message) {
                            localStorage.removeItem("user");
                            setIsCheckingUser(false)
                        } else {
                            window.alert("The server is having issues processing your request")
                            setIsCheckingUser(false)
                        }
                    })
            }, 2000);
        };
        if (current_user != null && !is_checking_user) {
            setIsCheckingUser(true)
            checkUser();
        }
    }, [navigate, current_user, setIsCheckingUser]);

    const logIn = () => {
        fetch("https://api.puzl.tech/auth/login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: "follow",
            body: JSON.stringify({username_or_email, password})
        })
            .then(r => r.json())
            .then(r => {
                if ('Login successful' === r.message) {
                    localStorage.setItem("user", JSON.stringify({username_or_email: r.data.username, token: r.data.token}))
                    props.setLoggedIn(true)
                    props.setUsernameEmail(r.data.username)
                    navigate("/")
                } else if ('Requires two-factor authentication' === r.message) {
                    props.setPhone(r.data.phone_number)
                    props.setUsernameEmail(username_or_email)
                    props.setPassword(password)
                    navigate("/2fa")
                } else {
                    window.alert(r.message)
                }
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        logIn()
        setPassword("");
    };

    const gotoSignUpPage = () => navigate("/register");
    const gotoPasswordResetPage = () => navigate("/password/reset");

    return (
        <>
            {is_checking_user ? (
                <Preloader />
            ) : (
                <div className='login__container'>
                    <h2>Login</h2>
                    <form className='login__form' onSubmit={handleSubmit}>
                        <label htmlFor='email'>Email or Username</label>
                        <input
                            type='text'
                            id='email'
                            name='email'
                            value={username_or_email}
                            required
                            onChange={(e) => setUsernameEmail(e.target.value)}
                        />
                        <label htmlFor='password'>Password</label>
                        <input
                            type='password'
                            name='password'
                            id='password'
                            minLength={8}
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className='loginBtn'>SIGN IN</button>
                        <p>
                            <span className='link' onClick={gotoPasswordResetPage}>
                                Forgot your password?
                            </span>
                        </p>
                        <p>
                            Don't have an account?{" "}
                            <span className='link' onClick={gotoSignUpPage}>
                                Sign up
                            </span>
                        </p>
                    </form>
                </div>
            )}
        </>
    );
};

export default Login;