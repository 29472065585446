import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "./Auth.css"
import Preloader from "../Components/Preloader/Preloader";

const PhoneVerify = (props) => {
    const [code, setCode] = useState("");
    const [should_load, setShouldLoad] = useState(true)
    const [code_sent, setCodeSent] = useState(false)
    const [phone_verified, setPhoneVerified] = useState(false)
    const navigate = useNavigate();
    const jwt = JSON.parse(localStorage.getItem("user")).token;

    useEffect(() => {
        const checkUser = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/phone/verify", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                    body: JSON.stringify({"phone_number": props.phone})
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setCodeSent(true)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert("The server is having issues processing your request")
                            navigate("/user/edit")
                        }
                    })
            }, 2000);
        }
        if (should_load === true && code_sent === false) {
            checkUser()
        }
    }, [navigate, code_sent, jwt, setCodeSent, should_load, setShouldLoad]);

    useEffect(() => {
        const verifyPhone = () => {
            setTimeout(() => {
                fetch("https://api.puzl.tech/auth/phone/verify/" + code, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                    body: JSON.stringify({"phone_number": props.phone})
                })
                    .then(r => r.json())
                    .then(r => {
                        if ('OK' === r.message) {
                            setShouldLoad(false)
                            setPhoneVerified(true)
                        } else if ("Unauthorized to perform this action." === r.message) {
                            navigate("/login")
                        } else {
                            window.alert(r.message)
                            navigate("/user/edit")
                        }
                    })
            }, 2000);
        }
        if (should_load === true && code !== "" && code_sent) {
            verifyPhone()
        }
    }, [navigate, should_load, setShouldLoad, setPhoneVerified, code_sent]);

    const onBack = (e) => {
        navigate("/user/edit")
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setShouldLoad(true)
    };

    let current_state = <div></div>

    if (phone_verified) {
        current_state = (
            <React.Fragment>
                <div className='verify'>
                    <h2 style={{ marginBottom: "30px" }}>Your phone has been verified</h2>
                    <button className='codeBtn' onClick={onBack}>BACK</button>
                </div>
            </React.Fragment>
        );
    } else if (code_sent) {
        current_state = (
            <div className='verify'>
                <h2 style={{ marginBottom: "30px" }}>Phone Verification</h2>
                <form className='verify__form' onSubmit={handleSubmit}>
                    <label htmlFor='code' style={{ marginBottom: "10px" }}>
                        A code has been sent your phone
                    </label>
                    <input
                        type='text'
                        name='code'
                        id='code'
                        className='code'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        required
                    />
                    <button className='codeBtn'>VERIFY</button>
                </form>
            </div>
        );
    }

    return (
        <>
            {should_load ? (
                <Preloader />
            ) : (
                current_state
            )}
        </>
    );
};

export default PhoneVerify;