import Card from 'react-bootstrap/Card';
import "./PUZLCard.css"

function PUZLCard(props) {
    return (
        <Card className="e-card">
            <Card.Body>
                <Card.Title className="e-card-title">@{props.username}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted e-card-subtitle">{props.created_at}</Card.Subtitle>
                <Card.Text>
                    {props.text}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default PUZLCard;